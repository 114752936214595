<template>
    <v-expansion-panel>
        <v-expansion-panel-header class="mt-2">
            BUSCAR PACIENTES
            <template v-slot:actions>
                 <v-btn class="mr-5" @click.native.stop="reload()" icon small>
                    <v-icon color="primary">mdi-reload</v-icon>
                </v-btn>
                <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-card>
                <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                    <v-tabs-slider color="red"></v-tabs-slider>

                    <v-tab href="#tab-1">
                        GESTIÓN DE PACIENTES
                        <v-icon>mdi-account-search</v-icon>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">
                        <v-card flat>
                            <v-card-text class="pa-1">
                                <GeneralFilter class="background border-box" :filterEndpoint="$API.patients.filterPatients" :search="search" @emptyFilter="getAllPatients" @filtered="filterHandler" 
                                    v-if="$store.getters['rolesUser/getCustomRole'](104)">
                                    <DxDataGrid class="isScrolledByGeneralFilter" :data-source="patients" key-expr="ID_Patient"
                                                :show-row-lines="showRowLines"
                                                :show-borders="showBorders"
                                                @exporting="onExporting"
                                                :row-alternation-enabled="rowAlternationEnabled">
                                        <DxPaging :page-size="10"/>
                                        <DxExport :enabled="true"/>
                                        <DxScrolling row-rendering-mode="virtual"/>
                                        <DxColumn :width="80" cell-template="show-template" caption="" v-if="$store.getters['rolesUser/getCustomRole'](105)"></DxColumn>
                                        <DxColumn :width="130" data-field="ID_Patient" caption="ID"></DxColumn>
                                        <DxColumn :width="130" data-field="ID_Customer" caption="ID Cliente"></DxColumn>
                                        <DxColumn data-field="FirstName" caption="Nombre"></DxColumn>
                                        <DxColumn data-field="LastName" caption="Apellidos"></DxColumn>
                                        <DxColumn :width="200" data-field="OldID" caption="Código alterno"></DxColumn> 
                                        <template #show-template="{data}">
                                            <a href="#" class="mx-1"
                                               @click="patientSelected(data)">
                                                <v-icon color="primary">mdi-eye</v-icon>
                                            </a>
                                        </template>
                                    </DxDataGrid>
                                </GeneralFilter>

                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import {DxDataGrid, DxColumn, DxScrolling, DxPaging,DxExport} from 'devextreme-vue/data-grid';
    import { mapGetters } from 'vuex'
    import { printToExcel } from "@/helpers/printToexcel";
    import GeneralFilter from '@/components/GeneralFilter.vue';

    export default {
        name: "PatientList",
        components: {DxDataGrid, DxColumn, DxScrolling, DxPaging, DxExport, GeneralFilter},
        props: ['win'],
        data() {
            return {
                search: {},
                dialog: false,
                dialogDelete: false,
                tab: null,
                patients: [],
                showPatient: false,
                showRowLines: true,
                showBorders: true,
                rowAlternationEnabled: true,
            }
        },
        computed: {
          ...mapGetters(['reloadData'])
        },
        watch: {
            reloadData: {
                immediate: true,
                handler: 'reloadPatients'
            }
        },
        mounted() {
            this.search.business = JSON.parse(localStorage.getItem("user")).businessid || ""
            this.search.branch = JSON.parse(localStorage.getItem("user")).branch || ""
            this.getAllPatients();
        },
        methods: {
            filterHandler(response) {
                this.patients = response
            },
            onExporting(e){
                printToExcel(e, "Pacientes");
            },
            getAllPatients() {
                this.$API.patients.getPatients(this.search)
                    .then(response => {
                        this.patients = response
                    })
            },
            reload(){
                this.getAllPatients();
            },
            async reloadPatients() {
                if (this.reloadData == 'patients') {
                    await this.getAllPatients();
                    this.$store.dispatch('reloadData', null)
                }
            },
            patientSelected(data) {
                this.$store.commit("patientData/changeView", "tab-1")
                this.$store.dispatch('addWindow', {
                    name: 'patientData',
                    component: 'PatientData',
                    unique: false,
                    meta: {patient: data.data}
                })
            },
        },
    }
</script>

<style scoped>
    .v-input__control {
        background: white !important;
    }

    .border-box {
        border-radius: 15px !important;
    }
    .background {
        background: white !important;
        color: white;
    }

</style>
